import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import HrefLangManager from '../components/HrefLangManager';
import Map from '../components/Map';
import ButtonCta from '../components/ButtonCta';
import TrustLogos from '../components/TrustLogos';
import { fpqUrl } from '../utils/variables';

const sideBySideData = {
	sideBySide1: {
		headline: 'Newest Hearing<br /> Technology',
		list: [
			'Super-fast sound processing',
			'Speech-enhancing algorithms',
			'Best-in-class features like Bluetooth, rechargeability and more'
		],
		imgSrc:
			'https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/collage-v2.jpg'
	},
	sideBySide2: {
		headline: 'Top Hearing<br/> Professionals',
		list: [
			'Over 200 locations across Canada',
			'In-person & virtual care appointments',
			'Personalized hearing assessments & fittings'
		],
		imgSrc:
			'https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/audiologist.jpg'
	},
	sideBySide3: {
		headline: 'Success Guaranteed',
		list: [
			'Customized Hearing Success Program<sup>&reg;</sup>',
			'30-day no-risk trial of our hearing aids',
			'100% money-back guarantee'
		],
		imgSrc:
			'https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/founders.jpg',
		listImg: true
	}
};

const SideBySide = ({ headline, list, imgSrc, reverseOrder, listImg }) => {
	return (
		<div className={`c-side-by-side ${reverseOrder ? 'c-side-by-side--reverse' : ''}`}>
			<div className="c-side-by-side__content">
				<div className="o-row-3">
					<h2 className="c-index-title" dangerouslySetInnerHTML={{ __html: headline }} />
				</div>
				<div className="o-row-3">
					<div className="c-separator"></div>
				</div>
				{listImg ? (
					<div className="o-row-3">
						<ul className="c-index-copy c-index-copy--list">
							{list.map((item, index) => (
								<li key={index} dangerouslySetInnerHTML={{ __html: item }} />
							))}
						</ul>
					</div>
				) : (
					<ul className="c-index-copy c-index-copy--list">
						{list.map((item, index) => (
							<li key={index} dangerouslySetInnerHTML={{ __html: item }} />
						))}
					</ul>
				)}
				{listImg && <TrustLogos />}
			</div>
			<div className="c-side-by-side__img">
				<img className="o-fluid-img" src={imgSrc} alt="" loading="lazy" />
			</div>
		</div>
	);
};

const IndexPage = () => {
	return (
		<Layout>
			<Seo />
			<HrefLangManager
				alternates={[
					['x-default', 'https://www.hear.com/'],
					['en', 'https://www.hear.com/'],
					['en-US', 'https://www.hear.com/'],
					['en-IN', 'https://www.hear.com/in/'],
					['en-ZA', 'https://www.hear.com/za/'],
					['en-MY', 'https://www.hear.com/my/'],
					['ko-KR', 'https://www.hear.com/kr/'],
					['en-CA', 'https://ca.hear.com/']
				]}
			/>
			<section className="c-index-hero">
				<div className="o-wrapper">
					<div className="c-index-hero__content">
						<div className="c-index-hero__content-left">
							<h1 className="c-index-hero__title">
								hear.com's
								<br /> hearing aids
								<br /> can change
								<br /> your life
							</h1>
							<ButtonCta
								copy={
									<>
										Check if you qualify
										<br />
										for a no-risk trial
									</>
								}
								classes="c-cta--center-mobile track-cta-hero"
							/>
						</div>
						<div className="c-index-hero__img">
							<picture>
								<source
									media="(max-width: 699px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good,w_550/v1/assets/na/seo-website/riley-v2.png"
								/>
								<source
									media="(min-width: 700px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good,w_869/v1/assets/na/seo-website/riley-v2.png"
								/>
								<img
									className="o-fluid-img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good,w_869/v1/assets/na/seo-website/riley-v2.png"
									alt=""
								/>
							</picture>
						</div>
					</div>
				</div>
			</section>
			<section className="c-logos-ticker">
				<div className="c-logos-ticker__featured">Featured in</div>
				<div className="c-logos-ticker__content">
					<div className="c-logos-ticker__row">
						<div className="c-logos-ticker__logo c-logos-ticker__logo--wall-st">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/wall-st-journal.svg"
								alt=""
							/>
						</div>
						<div className="c-logos-ticker__logo c-logos-ticker__logo--forbes">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/forbes.svg"
								alt=""
							/>
						</div>
						<div className="c-logos-ticker__logo c-logos-ticker__logo--fox">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/business-insider.svg"
								alt=""
							/>
						</div>
						<div className="c-logos-ticker__logo c-logos-ticker__logo--webmd">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/webmd.svg"
								alt=""
							/>
						</div>
						<div className="c-logos-ticker__logo c-logos-ticker__logo--wall-st">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/wall-st-journal.svg"
								alt=""
							/>
						</div>
						<div className="c-logos-ticker__logo c-logos-ticker__logo--forbes">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/forbes.svg"
								alt=""
							/>
						</div>
						<div className="c-logos-ticker__logo c-logos-ticker__logo--fox">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/business-insider.svg"
								alt=""
							/>
						</div>
						<div className="c-logos-ticker__logo c-logos-ticker__logo--webmd">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/webmd.svg"
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="c-index-intro">
				<div className="o-wrapper">
					<div className="c-index-intro__content">
						<div className="o-row-3">
							<h2 className="c-index-title">Smaller, smarter hearing aids</h2>
						</div>
						<div className="o-row-3">
							<div className="c-separator"></div>
						</div>
						<div className="o-row-5">
							<ul className="c-index-copy c-index-copy--list">
								<li>Nearly invisible designs</li>
								<li>Smartphone connectivity</li>
								<li>Best speech clarity</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="o-wrapper">
					<div className="c-index-intro__content">
						<div className="c-index-intro__content-img">
							<picture>
								<source
									media="(max-width: 499px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/intro-can.jpg"
								/>
								<source
									media="(min-width: 500px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/intro-can-desk.jpg"
								/>
								<img
									className="o-fluid-img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:good/v1/assets/na/seo-website/intro-can-desk.jpg"
									alt=""
									loading="lazy"
								/>
							</picture>
						</div>
					</div>
				</div>
			</section>
			<section className="c-section c-section--alt u-text-center">
				<div className="o-wrapper">
					<div className="o-row-3">
						<h2 className="c-index-title u-color-secondary">
							Get started.
							<br />
							Select your province below.
						</h2>
					</div>
					<div className="o-row-3">
						<p className="c-index-copy">
							hear.com is the 3rd largest hearing care company in the world, with licensed professionals conveniently
							located across Canada and near you.
						</p>
					</div>
					<div className="o-wrapper o-wrapper--small">
						<Map />
					</div>
				</div>
			</section>
			<section className="c-section">
				<div className="o-wrapper">
					<SideBySide
						headline={sideBySideData.sideBySide1.headline}
						list={sideBySideData.sideBySide1.list}
						imgSrc={sideBySideData.sideBySide1.imgSrc}
					/>
					<SideBySide
						headline={sideBySideData.sideBySide2.headline}
						list={sideBySideData.sideBySide2.list}
						imgSrc={sideBySideData.sideBySide2.imgSrc}
						reverseOrder={true}
					/>
					<SideBySide
						headline={sideBySideData.sideBySide3.headline}
						list={sideBySideData.sideBySide3.list}
						imgSrc={sideBySideData.sideBySide3.imgSrc}
						listImg={sideBySideData.sideBySide3.listImg}
					/>
				</div>
			</section>
			<section className="c-section c-section--alt">
				<div className="o-wrapper">
					<div className="o-row-4">
						<h2 className="c-index-title u-text-center">What are you waiting for?</h2>
					</div>
					<div className="u-text-center">
						<ButtonCta
							url={fpqUrl}
							localLink={true}
							copy="Check if you qualify for a no-risk trial"
							classes="c-cta c-cta--xl track-cta-main"
						/>
					</div>
				</div>
			</section>
			<section className="c-section u-text-center">
				<div className="o-wrapper">
					<div className="o-row-3">
						<h2 className="c-index-title" style={{ maxWidth: '840px', marginLeft: 'auto', marginRight: 'auto' }}>
							At hear.com we believe everyone should <strong>hear well to live well.</strong>
						</h2>
					</div>
					<div className="o-row-3">
						<div className="c-separator c-separator--center"></div>
					</div>
					<p className="c-index-copy" style={{ maxWidth: '740px', marginLeft: 'auto', marginRight: 'auto' }}>
						Regardless of your hearing needs, budget and lifestyle, we'll make it easy and find the right hearing aids
						for you.
					</p>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
